import { map, defaultTo } from 'ramda'

import { getWatchFullUrl } from '../utils'
import { getProductCategory } from './product'

/**
 * @param {Object} contentItem One of movie, series, season or episode
 * @param {Object[]} products The products associated with the contentItem
 * @param {String} The language mainly used in this contentItem
 */
export const potentialAction = (contentItem, products, language) => ({
  '@type': 'WatchAction',
  target: [
    {
      '@type': 'EntryPoint',
      urlTemplate: getWatchFullUrl(contentItem),
      inLanguage: language,
      actionPlatform: [
        'http://schema.org/DesktopWebPlatform',
        'http://schema.org/MobileWebPlatform',
        'http://schema.org/IOSPlatform',
        'http://schema.googleapis.com/GoogleVideoCast'
      ]
    }
  ],
  expectsAcceptanceOf: map(product => ({
    '@type': 'Offer',
    availabilityStarts: contentItem.start,
    availabilityEnds: contentItem.end,
    category: getProductCategory(product),
    name: product.name,
    price: product.currentPrice,
    priceCurrency: 'NZD',
    seller: {
      '@type': 'Organization',
      name: 'Lightbox',
      sameAs: 'https://lightbox.co.nz'
    },
    eligibleRegion: {
      '@type': 'Country',
      name: 'NZ'
    }
  }), defaultTo([], products))
})
