import qs from 'query-string'
import { compose, lifecycle } from 'recompose'

import MY_LIST_QUERY from '../../graphql/queries/my-list.gql'
import ADD_TO_MY_LIST_MUTATION from '../../graphql/mutations/add-to-my-list.gql'

// Higher order component to add title or series to my list
// requires:
//  - withRouter
//  - withIsAuthenticated
export default compose(
  lifecycle({
    componentDidMount() {
      const {
        location, isAuthenticated, client, contentItem
      } = this.props
      const add = qs.parse(location.search).add

      if (!(isAuthenticated && typeof add !== 'undefined')) {
        return
      }

      client.mutate({
        mutation: ADD_TO_MY_LIST_MUTATION,
        variables: {
          contentItemId: contentItem.id
        },
        refetchQueries: [{
          query: MY_LIST_QUERY,
          options: {
            notifyOnNetworkStatusChange: true
          }
        }]
      })
    }
  })
)
