import { withStateHandlers } from 'recompose'
import { pathOr, propOr } from 'ramda'
import { isSmallerThan1024, isSmallerThan768 } from '../lib/screen'

const withMoreInfoExpandState = withStateHandlers(
  ({ contentItem }) => {
    if (
      !isSmallerThan768({ screenWidth: window.innerWidth }) &&
      isSmallerThan1024({ screenWidth: window.innerWidth })) {
      return {
        moreInfoExpanded: true,
        showMoreInfoButton: false
      }
    }
    const description = propOr('', 'description', contentItem)
    const linesToShowMoreInfoButton = 4
    const eachCharacterWidth = 8
    const lineWidth = window.innerWidth * 0.6
    const linesCount = (pathOr(0, ['length'], description) * eachCharacterWidth) / lineWidth
    return linesCount > linesToShowMoreInfoButton
      ? {
        moreInfoExpanded: false,
        showMoreInfoButton: true
      }
      : {
        moreInfoExpanded: true,
        showMoreInfoButton: false
      }
  },
  {
    toggleMoreInfoExpanded:
      ({ moreInfoExpanded }) => () => ({ moreInfoExpanded: !moreInfoExpanded })
  }
)

export default withMoreInfoExpandState
