import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { T, F } from 'ramda'

import TitleDetail from '../../containers/title/title-detail'
import TitleList from '../../containers/title/title-list'
import styles from './title.css'
import { JSONLD } from '../jsonld/index'
import { createMovieLDSchema } from '../../lib/jsonld/movie'
import { THEME_OPTIONS } from '../../constants'

import TITLE_COMPONENT_FRAGMENT from '../../../graphql/fragments/title-component.gql'

function Title({
  component, theme, screenWidth, addToMyList, removeFromMyList, isContentItemOnMyList
}) {
  const title = component.title
  const isKids = theme === THEME_OPTIONS.light

  return (
    <div className={`${styles.title} ${theme}`}>
      <TitleDetail contentItem={title} theme={theme} screenWidth={screenWidth} />
      {
        !isKids && (
          <div className={styles.columnsWrapper}>
            <TitleList
              title={title}
              addToMyList={addToMyList}
              removeFromMyList={removeFromMyList}
              isContentItemOnMyList={isContentItemOnMyList}
              theme={theme}
            />
          </div>
        )
      }
      <JSONLD schema={createMovieLDSchema(title)} type="Movie" />
    </div>
  )
}

Title.propTypes = {
  component: propType(TITLE_COMPONENT_FRAGMENT).isRequired,
  theme: PropTypes.string.isRequired,
  screenWidth: PropTypes.number.isRequired,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func,
  isContentItemOnMyList: PropTypes.func
}

Title.defaultProps = {
  addToMyList: T,
  removeFromMyList: T,
  isContentItemOnMyList: F
}

export default Title
