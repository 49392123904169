import { map } from 'ramda'
import { getWatchFullUrl } from '../utils'
import { image } from './image'

export const hasPart = (extras, language, type) => {
  return map(extra => ({
    '@type': type,
    description: extra.description,
    image: image(extra.images),
    potentialAction: {
      '@type': 'WatchAction',
      target: [
        {
          '@type': 'EntryPoint',
          urlTemplate: getWatchFullUrl(extra),
          inLanguage: language,
          actionPlatform: [
            'http://schema.org/DesktopWebPlatform',
            'http://schema.org/MobileWebPlatform',
            'http://schema.org/IOSPlatform',
            'http://schema.googleapis.com/GoogleVideoCast'
          ]
        }
      ],
      expectsAcceptanceOf: {
        category: 'free',
        availabilityStarts: extra.start,
        availabilityEnds: extra.end,
        eligibleRegion: {
          '@type': 'Country',
          name: 'NZ'
        }
      }
    }
  }), extras)
}
