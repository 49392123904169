import { getDetailsFullUrl } from '../utils'
import { convertMinutesToISODuration } from '../date-time'
import { image } from './image'
import { getPersonList } from './person'
import { hasPart } from './hasPart'
import { releasedEvent } from './releasedEvent'
import { potentialAction } from './potentialAction'

export const createMovieLDSchema = (movie) => {
  return {
    '@id': getDetailsFullUrl(movie),
    name: movie.title,
    url: getDetailsFullUrl(movie),
    description: movie.description,
    duration: convertMinutesToISODuration(movie.duration),
    image: image(movie.images),
    inLanguage: movie.language,
    dateCreated: movie.year,
    sameAs: movie.socialLinks?.imdbUrl,
    director: getPersonList(movie.directors),
    actor: getPersonList(movie.actors),
    releasedEvent: releasedEvent(movie.year, movie.country),
    potentialAction: potentialAction(movie, movie.products, movie.language),
    hasPart: hasPart(movie.videoExtras, movie.language, 'Clip')
  }
}
