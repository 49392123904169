import React from 'react'
import PropTypes from 'prop-types'
import { T } from 'ramda'

import CarouselTile from '../../../containers/carousel/tile'
import { THEME_OPTIONS } from '../../../constants'

import styles from './recommendations.css'

function Recommendations({
  tiles,
  theme,
  isContentItemOnMyList,
  addToMyList,
  removeFromMyList,
  listTitle
}) {
  return tiles.length ? (
    <div className={styles.recommendationsWrapper}>
      {tiles.map((tile, index) => {
        return (
          <div key={tile.image} className={styles.recommendation}>
            <CarouselTile
              tile={tile}
              index={index}
              theme={theme}
              listTitle={listTitle}
              isOnMyList={isContentItemOnMyList(tile.contentItem.id)}
              addToMyList={addToMyList}
              removeFromMyList={removeFromMyList}
            />
          </div>
        )
      })}
    </div>
  ) : null
}

Recommendations.propTypes = {
  tiles: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  theme: PropTypes.oneOf([THEME_OPTIONS.dark, THEME_OPTIONS.light]).isRequired,
  isContentItemOnMyList: PropTypes.func,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func,
  listTitle: PropTypes.string.isRequired
}

Recommendations.defaultProps = {
  tiles: [],
  addToMyList: T,
  removeFromMyList: T,
  isContentItemOnMyList: T
}

export default Recommendations
