import React from 'react'
import ReactSVG from 'react-svg'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { compose, withState, withHandlers } from 'recompose'
import classNames from 'classnames'

import CastCrew from './cast-crew'
import Trailers from './trailers'
import Synopsis from './synopsis'
import styles from './more-info.css'

import arrowDown from '../../../images/down-arrow.svg'
import arrowUp from '../../../images/up-arrow.svg'
import arrowDownDark from '../../../images/down-arrow-dark.svg'
import arrowUpDark from '../../../images/up-arrow-dark.svg'
import { THEME_OPTIONS } from '../../constants'
import CONTENT_ITEM_FRAGMENT from '../../../graphql/fragments/content-item.gql'
import { navigationGa } from '../../lib/analytics/ga'

import { withScreenWidthWatching } from '../../hoc/with-screen-width-watching'

export function MoreInfoComponent({
  contentItem,
  isExpanded,
  onMoreInfoClick,
  theme,
  showMoreInfoButton,
  synopsisClassName,
  className,
  screenWidth
}) {
  const upIcon = theme === THEME_OPTIONS.light ? arrowUpDark : arrowUp
  const downIcon = theme === THEME_OPTIONS.light ? arrowDownDark : arrowDown

  const linkIcon = isExpanded ? upIcon : downIcon
  const linkText = isExpanded ? 'LESS INFO' : 'MORE INFO'

  return (
    <div
      className={classNames(
        styles.moreInfo,
        { [styles.active]: isExpanded },
        className
      )}
      data-lbx-e2e="Synopsis"
    >
      <div className={styles.synopsisAndButton}>
        {
          (!isExpanded && theme === 'LIGHTBOX_KIDS')
            ? <div />
            : (
              <Synopsis
                className={synopsisClassName}
                contentItem={contentItem}
                visibility={isExpanded}
              />
            )
        }
        {
          showMoreInfoButton && (
            <div
              className={styles.linkWrapper}
            >
              <div
                className={classNames(styles.link, {
                  [styles.isRental]: contentItem.isRental
                })}
                onClick={onMoreInfoClick}
              >
                <span
                  className={styles.linkText}
                  data-lbx-e2e="More Info"
                >
                  {linkText}
                </span>
                <ReactSVG
                  src={linkIcon}
                  beforeInjection={(svg) => {
                    svg.setAttribute('class', styles.linkIcon)
                  }}
                />
              </div>
            </div>
          )
        }
      </div>
      {
        isExpanded && theme !== 'LIGHTBOX_KIDS' // Add in this div the components inside the More Info section
          ? (
            <div>
              <CastCrew
                contentItem={contentItem}
                theme={theme}
                screenWidth={screenWidth}
              />
              <Trailers
                contentItem={contentItem}
                theme={theme}
                screenWidth={screenWidth}
              />
            </div>
          )
          : null
      }
    </div>
  )
}

MoreInfoComponent.propTypes = {
  contentItem: propType(CONTENT_ITEM_FRAGMENT).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  screenWidth: PropTypes.number,
  showMoreInfoButton: PropTypes.bool,
  onMoreInfoClick: PropTypes.func,
  synopsisClassName: PropTypes.string,
  className: PropTypes.string
}

MoreInfoComponent.defaultProps = {
  showMoreInfoButton: true,
  onMoreInfoClick: () => { },
  synopsisClassName: '',
  className: '',
  screenWidth: window.innerWidth
}

const enhance = compose(
  withState('isExpanded', 'setIsExpanded', false),
  withHandlers({
    onMoreInfoClick: ({ isExpanded, setIsExpanded }) => () => {
      navigationGa({ action: isExpanded ? 'Less Info' : 'More Info' })
      setIsExpanded(!isExpanded)
    }
  }),
  withScreenWidthWatching
)

export default enhance(MoreInfoComponent)
