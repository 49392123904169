import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import classnames from 'classnames'

import Clamped from './clamped'
import styles from './synopsis.css'

import { formatParagraphs } from '../../lib/content-item'

import CONTENT_ITEM_FRAGMENT from '../../../graphql/fragments/content-item.gql'

function Synopsis({
  contentItem,
  visibility,
  className
}) {
  return (
    <div
      className={classnames(
        styles.synopsis,
        className
      )}
    >
      {
        visibility
          ? formatParagraphs(contentItem.description)
          : <Clamped lines={2}>{contentItem.description}</Clamped>
      }
    </div>
  )
}

Synopsis.propTypes = {
  contentItem: propType(CONTENT_ITEM_FRAGMENT).isRequired,
  visibility: PropTypes.bool,
  className: PropTypes.string
}

Synopsis.defaultProps = {
  visibility: false,
  className: ''
}

export default Synopsis
