import React from 'react'
import { propType } from 'graphql-anywhere'
import PropTypes from 'prop-types'

import styles from './trailers.css'

import CONTENT_ITEM_FRAGMENT from '../../../../graphql/fragments/content-item.gql'
import { TrailerThumbnail } from '../../../containers/shared/trailers/trailer'
import { isKids } from '../../../lib/utils'
import {
  COMPONENT_NAME_SERIES_DETAIL,
  COMPONENT_NAME_SERIES_DETAIL_KIDS
} from '../../../lib/analytics/custom-dimensions'

import { highRoadFreeEpisodes } from '../highRoadFreeEpisodes'

function Trailers({ contentItem, theme, screenWidth }) {
  const videoExtras = contentItem.videoExtras.filter(
    video => !highRoadFreeEpisodes.includes(video.id)
  )

  return videoExtras.length ? (
    <div className={styles.trailersWrapper}>
      <div className={styles.trailers}>
        {videoExtras.map(trailer => {
          return (
            <TrailerThumbnail
              trailer={trailer}
              id={trailer.id}
              contentItem={contentItem}
              contentItemId={contentItem.id}
              key={trailer.id}
              renderedInComponent={
                isKids(theme)
                  ? COMPONENT_NAME_SERIES_DETAIL_KIDS
                  : COMPONENT_NAME_SERIES_DETAIL
              }
            />
          )
        })}
      </div>
    </div>
  ) : null
}

Trailers.propTypes = {
  contentItem: propType(CONTENT_ITEM_FRAGMENT).isRequired,
  theme: PropTypes.string.isRequired,
  screenWidth: PropTypes.number.isRequired
}

export default Trailers
