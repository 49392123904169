import { withRouter } from 'react-router-dom'
import {
  compose,
  lifecycle,
  withState
} from 'recompose'

import TitleList from '../../components/title/title-list'
import { withScreenWidthWatching } from '../../hoc/with-screen-width-watching'

export const enhance = compose(
  withRouter,
  withState('selectedTab', 'setSelectedTab'),
  lifecycle({
    shouldComponentUpdate(nextProps) {
      return this.props.selectedTab !== nextProps.selectedTab
    }
  }),
  withScreenWidthWatching
)

export default enhance(TitleList)
