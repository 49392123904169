import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { YOU_MAY_ALSO_LIKE_ITEM_LIMIT } from '../../constants'
import Recommendations from '../shared/recommendations'

import styles from './title-list.css'

const TABS = {
  recommendedTiles: 'recommendedTiles'
}
const TABS_LABELS = {
  recommendedTiles: 'YOU MAY ALSO LIKE'
}

function TitleList({
  title,
  isContentItemOnMyList,
  addToMyList,
  removeFromMyList,
  theme,
  setSelectedTab,
  selectedTab
}) {
  if (!title) {
    return null
  }

  const isShowRecommendations = title.recommendedTiles && title.recommendedTiles.length > 0

  return (
    <>
      <div className={styles.titleListWrapper}>
        <div className={styles.titleListTabWrapper}>
          {
            isShowRecommendations && (
              <div
                className={classNames(styles.tab, {
                  [styles.active]: TABS.recommendedTiles === selectedTab
                })}
                onClick={() => { setSelectedTab(TABS.recommendedTiles) }}
              >
                {TABS_LABELS[TABS.recommendedTiles]}
              </div>
            )
          }
        </div>

        {
          TABS.recommendedTiles === selectedTab && (
            <Recommendations
              tiles={title.recommendedTiles.slice(0, YOU_MAY_ALSO_LIKE_ITEM_LIMIT)}
              theme={theme}
              listTitle="You May Also Like"
              isContentItemOnMyList={isContentItemOnMyList}
              addToMyList={addToMyList}
              removeFromMyList={removeFromMyList}
            />
          )
        }
      </div>
    </>
  )
}

TitleList.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isContentItemOnMyList: PropTypes.func.isRequired,
  addToMyList: PropTypes.func.isRequired,
  removeFromMyList: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.string
}

TitleList.defaultProps = {
  selectedTab: TABS.recommendedTiles
}

export default TitleList
